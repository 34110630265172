@font-face {
  font-family: 'icomoon';
  src: url("./fonts/icomoon.eot?6h04jv");
  src: url("./fonts/icomoon.eot?6h04jv#iefix") format("embedded-opentype"), url("./fonts/icomoon.ttf?6h04jv") format("truetype"), url("./fonts/icomoon.woff?6h04jv") format("woff"), url("./fonts/icomoon.svg?6h04jv#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-od"], [class*=" icon-od"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-od-menu-researcher:before {
  content: "\e900"; }

.icon-od-menu-organization:before {
  content: "\e901"; }

.icon-od-menu-tcffile:before {
  content: "\e902"; }

.icon-od-menu-dashboard:before {
  content: "\e903"; }

.icon-od-menu-doctor:before {
  content: "\e904"; }

.icon-od-menu-inquiry:before {
  content: "\e905"; }

.icon-od-menu-patient:before {
  content: "\e906"; }
